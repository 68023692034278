<template>
	<div class='box'>
		<heads title="首页" :showindex='Sindex'></heads>
		<div class="main">
			<el-table :data="tableData" border>
				<el-table-column align="center" prop="examinaTime" label="考试时间" width="180" />
				<el-table-column align="center" prop="examinaProduct" label="考试产品" width="180" >
					<template #default="scope">
						<span v-if="scope.row.examinaProduct == 1">托业</span>
						<span v-if="scope.row.examinaProduct == 2">SSAT</span>
						<span v-if="scope.row.examinaProduct == 3">托业桥</span>
						<span v-if="scope.row.examinaProduct == 4">行测</span>
						<span v-if="scope.row.examinaProduct == 5">专业</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="address" label="考试科目" >
					<template #default="scope">
						<span v-if="scope.row.examinaSubject == 1">LR</span>
						<span v-if="scope.row.examinaSubject == 2">SW</span>
						<span v-if="scope.row.examinaSubject == 3">S</span>
						<span v-if="scope.row.examinaSubject == 4">R</span>
						<span v-if="scope.row.examinaSubject == 5">初级-三年级</span>
						<span v-if="scope.row.examinaSubject == 6">初级-四年级</span>
						<span v-if="scope.row.examinaSubject == 7">中级</span>
						<span v-if="scope.row.examinaSubject == 8">高级</span>
						<span v-if="scope.row.examinaSubject == 9">行测</span>
						<span v-if="scope.row.examinaSubject == 10">专业</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="signUpNum" label="需监考考生" />
				<el-table-column align="center" label="操作">
					<template #default="scope">
						<div style="display: flex;justify-content: space-around;">
							<div class="showbtn" @click="userlick(scope.row)">
								<img style="width: 14px;height: 14px;margin-right: 5px;" src="@/assets/home/h1.png" alt="" sizes=""
									srcset="" />
								<div>考生签到列表</div>
							</div>
							<div class="showbtn"  @click="Reportclick(scope.row)">
								<img style="width: 14px;height: 14px;margin-right: 5px;" src="@/assets/home/h2.png" alt="" sizes=""
									srcset="" />
								<div>线上报告管理</div>
							</div>
							<div class="showbtn" @click="onelick(scope.row)" >
								<img style="width: 14px;height: 14px;margin-right: 5px;" src="@/assets/home/h3.png" alt="" sizes=""
									srcset="" />
								<div>线上考试码</div>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div
				style="padding-bottom: 20px; display: flex;justify-content: center;padding-top: 20px;background-color: #fff;">
				<el-pagination background 
					layout="prev, pager, next" :total="total" 
					 @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import {homelist} from "@/api/index"
	import heads from '@/components/head'

	import {
		ElMessage
	} from 'element-plus'
	export default {
		// 组件名
		name: 'hoMe',
		// 注册组件
		components: {
			heads
		},
		// 接收父组件数据
		// props: {},
		// 组件数据
		data() {
			return {
				tableData: [],
				page:{
					current:1,
					size:10
				},
				total:1
			}
		},
		// 计算属性
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {
			this.getuhomelist()
		},
		mounted() {},
		// 组件方法
		methods: {
			handleCurrentChange(e){
				console.log(e)
				this.page.current = e
			},
			handleSizeChange(e){
				console.log(e)
			},
			getuhomelist(){
				homelist(this.page).then(res => {
					console.log(res.data.total)
					this.tableData = res.data.records
					this.total = Number( res.data.total)  
				})
			},
			userlick(row) {
				this.$router.push({
					name: 'userlist',
					query:{
						sessionId:row.sessionId,
						examinaPlaceId:row.examinaPlaceId
					}
				})
			},
			onelick(row) {
				this.$router.push({
					name: 'online',
					query:{
						sessionId:row.sessionId,
						examinaPlaceId:row.examinaPlaceId
					}
				})
			},
			Reportclick(row) {
				this.$router.push({
					name: 'Report',
					query:{
						sessionId:row.sessionId,
						examinaPlaceId:row.examinaPlaceId
					}
				})
			},
			// 表格样式设置
			rowClass() {
				return "text-align: center;";
			},
		},
	}
</script>
<style lang='scss'>
	.box {
		background-color: #F7F7F7;
		min-height: 100vh;

		.main {
			max-width: 1600px;
			margin-top: 20px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.showbtn {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 20px;
	}
</style>