import request from "@/utils/request";
/**
 *
 * @param {*} 查询管理相关
 * @returns
 */
// PC端登录
export function login(data) {
    return request({
        url: '/pc/user/login',
        method: 'post',
        data
    })
}

export function sendcode(query) {
    return request({
        url: '/pc/user/send/code',
        method: 'GET',
        params: query
    })
}

export function userInfo(query) {
    return request({
        url: '/pc/user/userInfo',
        method: 'GET',
        params: query
    })
}
export function userInfoedit(data) {
    return request({
        url: '/pc/user/edit',
        method: 'post',
        data
    })
}
export function explain(data) {
    return request({
        url: '/pc/home/invigilation/explain',
        method: 'post',
        data
    })
}

export function homelist(data) {
    return request({
        url: '/pc/home/list',
        method: 'post',
        data
    })
}
// pc考生签到列表
export function signlist(data) {
    return request({
        url: '/pc/home/sign/list',
        method: 'post',
        data
    })
}
// 手动签到
export function signIn(data) {
    return request({
        url: '/pc/home/mo/sign/in',
        method: 'post',
        data
    })
}

// 手动签到
export function codelist(data) {
    return request({
        url: '/pc/home/examina/code/list',
        method: 'post',
        data
    })
}

// PC线上报告管理
export function reportDetail(data) {
    return request({
        url: '/pc/home/report/detail',
        method: 'post',
        data
    })
}
// 提交线上报告
export function submitReport(data) {
    return request({
        url: '/pc/home/submit/report',
        method: 'post',
        data
    })
}

export function homedetail(query) {
    return request({
        url: '/pc/home/detail',
        method: 'GET',
        params: query
    })
}